import { Component, Input, OnInit } from '@angular/core';
import { timezoneNames } from '../../../custom_scripts/config';
@Component({
  selector: 'resplendent-timezone-dialog',
  templateUrl: './timezone-dialog.component.html',
  styleUrls: ['./timezone-dialog.component.scss'],
})
export class TimezoneDialogComponent implements OnInit {
  @Input() Column;
  Timezones = timezoneNames();
  FilteredTimezones = [];
  constructor() {}

  ngOnInit(): void {
    this.FilteredTimezones = JSON.parse(JSON.stringify(this.Timezones));
  }
  Search(event) {
    this.FilteredTimezones = this.filterList(
      event.target.value,
      this.Timezones,
      [this.Column.timezone],
    );
  }

  filterList(filter, list, listOfExceptions = []) {
    //this function filters the values of a list
    let FilteredList = [];
    for (let item of list) {
      try {
        if (
          (item + '').toLowerCase().includes(filter.toLowerCase()) ||
          listOfExceptions.indexOf(item) != -1
        ) {
          FilteredList.push(item);
        }
      } catch {}
    }
    return FilteredList;
  }
}
